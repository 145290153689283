import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    InputLabel,
    Rating,
    Select,
    Slide, TextField, Typography
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../../form/TextInput";
import DateInput from "../../form/DateInput";
import SelectX from "../../form/SelectX";
import { Project } from "../../../api/Endpoints/Project";
import DynamicChip from "../../../utils/DynamicChip";
import { FormHelpers } from "../../../helpers/FormHelpers";
import { FollowUp } from "../../../api/Endpoints/FollowUp";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { Task } from "../../../api/Endpoints/Task";
import { Activities } from "../../../api/Endpoints/Activities";
import { ErrorMessage } from "@hookform/error-message";
import LoadingScreen from '../../form/progress';
import { Users } from '../../../api/Endpoints/Users';
import { Auth } from '../../../api/Endpoints/Auth';
import ReactSelector from 'react-select';
import { Employee } from '../../../api/Endpoints/Employee';
import { styled } from '@mui/material/styles';
import moment from 'moment';
const CustomRatingGreen = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: 'green', // filled color
    },
    '& .MuiRating-iconHover': {
      color: 'green', // hover color
    },
    '& .MuiRating-iconEmpty': {
      color: 'green', // outline color for empty stars
    },
  });
  const CustomRatingRed = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: 'red', // filled color
    },
    '& .MuiRating-iconHover': {
      color: 'red', // hover color
    },
    '& .MuiRating-iconEmpty': {
      color: 'red', // outline color for empty stars
    },
  });
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
    title: yup.string().required(),
    task: yup.string(),
    projects_id: yup.object().nullable()
})

const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme) });

    const [selectedPriority, setSelectedPriority] = useState(3);
    const [open, setOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const handleClickOpen = () => { props.onNew(); };
    const handleClose = () => { setOpen(false); };
    const [recentProjects, setRecentProjects] = useState([])
    const [currentUser, setCurrentUser] = useState(0)
    const [tasks, setTasks] = useState([])
    const [employees, setEmployees] = useState([])
    const [taskId, setTaskId] = useState()
    const [durationMinutes, setDurationMinutes] = React.useState();
    const [durationHours, setDurationHours] = React.useState();
    const [starValueGreen, SetStarValueGreen] = useState(0);
  const [starValueRed, SetStarValueRed] = useState(0);
    const [taskStatus, setTaskStatus] = useState([
        "Not Started",
        "In progress",
        "Review pending",
        "In review"
    ])
    const handleGreenChange = (event, newValue) => {
        if (newValue > starValueGreen) {
            SetStarValueRed(starValueRed - 1);
          } else {
            SetStarValueRed(starValueRed + 1);
          }
          SetStarValueGreen(newValue);
      };
    
      const handleRedChange = (event, newValue) => {
        if (newValue > starValueRed) {
            SetStarValueGreen(starValueGreen - 1);
          } else {
            SetStarValueGreen(starValueGreen + 1);
          }
          SetStarValueRed(newValue);
      };
      console.log("grreen",starValueGreen)
      console.log("reed", starValueRed)
    //component variables
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })

    
    // fetching employees
    const fetchEmployees = (e) => {
        Employee.get({ keyword: e, limit: 100 }).then(response => {
            // console.log(response.data.data.data);
            if (typeof response.data.data.data !== "undefined") {
                setEmployees(response.data.data.data)
            } else {
                return [];
            }
        })
    }

    // const fetchTask = (e) => {
    //     return Task.get({ keyword: e, assigned_to: currentUser, status: "Not Started" }).then(response => {
    //         if (typeof response.data.data.data !== "undefined") {
    //             console.log();
    //             return response.data.data.data;
    //         } else {
    //             return [];
    //         }
    //     })
    // }
    
    const fetchTasks = (id) => {
        Task.get({ assigned_to: id, status: "Not Started" }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                console.log();
                setTasks(response.data.data.data)
                return response.data.data.data;
            } else {
                return [];
            }
        })
    }


    
    


    

    const onSubmit = (data) => {
        console.log(data);
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })



        let dataToSubmit = {
            id: props.editId,
            projects_id: data.projects_id?.id,
            title: data.title,
            description: data.description,
            due_date: data.due_date,
            assigned_to_id: data.assign_to?.id,
            reviewer_id: data.reviewer_id?.id,
            project_tasks_id: taskId,
            duration_hours: durationHours,
            duration_minutes: durationMinutes,
            // created_at: moment().format('YYYY-MM-DD'),

        };


        let action;
        if (props.editId > 0) {
            console.log("dataToSubmit", dataToSubmit);
            action = Activities.update(dataToSubmit);
        } else {
            action = Activities.add(dataToSubmit);
        }

        action.then(response => {
            console.log(response);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            toast.success(response.data.message)
            props.onUpdate();
            props.setEditId()
            setOpen(false);
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            toast.error("Internal server error");
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })
    }

    const fetchActivityDetails = async () => {
        setLoading(true)
        let task = await Activities.getDetails({ id: props.editId });
        if (task.data.status === "success") {
            let data = task.data.data;
            setValue('title', data.title);
            setValue('description', data.description);
            // setValue('durationMinutes', data.duration_minutes);
            // setValue('durationHours', data.duration_hours);
            setValue('start_time', data.start_time);
            setValue('end_time', data.end_time);
            setValue('projects_id', { id: data.project?.id, name: data.project?.name });
            setValue('task', data.project_task?.title);
            setSelectedPriority(data.priority);
            setDurationMinutes(data.duration_minutes)
            setDurationHours(data.duration_hours)
        }
        setLoading(false)
    };

    const fetchRecentProjects = () => {
        Users.recentProjects().then((response) => {
            console.log(response);
            setRecentProjects(response.data.data)

        })
    }

    const fetchUser = async () => {
        const accessToken = window.localStorage.getItem('accessToken');
        const user = await Auth.me(accessToken);
        setCurrentUser(user.data.data.id)
        console.log(user);
        // setValue('reviewer_id', { id: user.data.data.id, name: user.data.data.name })
        fetchTasks(user.data.data.id)
    }
    
    // console.log(currentUser);
    useEffect(() => {
        setRefresh(Math.random())
    }, [watch('projects_id')])

    useEffect(() => {
        if (open) {
            fetchRecentProjects()
            fetchUser()
            fetchEmployees()
        }
    }, [])

    // useEffect(() => {
    //     fetchTasks()
    //     setRefresh(Math.random())
    // }, [currentUser])


    useEffect(() => {
            if (parseInt(props.editId) > 0) {
                fetchActivityDetails();
                fetchUser()
                setOpen(true);
            } else if (parseInt(props.editId) === 0) {
                setOpen(true);
                fetchUser()
            }

    }, [props.editId])
    // const classes = useStyles();

    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                Add Rating
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{parseInt(props.editId) > 0 ? "" : "Rate Employees"}</DialogTitle>

                {!loading ? (

                    <>
                        <DialogContent>
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <ErrorMessage
                                    errors={errors}
                                    name="multipleErrorInput"
                                    render={({ messages }) =>
                                        messages &&
                                        Object.entries(messages).map(([type, message]) => (
                                            <p key={type}>{message}</p>
                                        ))
                                    }
                                />

                               
                                <Grid sx={{}} mt={2}>
                                    <InputLabel sx={{
                                        color: "black",
                                        fontSize: "13px",
                                        pb: .5,
                                        fontWeight: 500
                                    }}>Choose Employee</InputLabel>
                                    <ReactSelector
                                        onInputChange={fetchEmployees}
                                        // placeholder="Reviewer"
                                        styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                        options={employees}
                                        getOptionLabel={option => option.name}
                                        getOptionValue={option => option.id}

                                        // inputValue={inProject}
                                        value={
                                            tasks.filter(options =>
                                                options.title === watch('employees')
                                            )
                                        }
                                        name='employees'
                                        isClearable={true}
                                        defaultValue={(watch('employees'))}
                                        // onChange={(selectedOption) => selectTask(selectedOption)}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="task"
                                        render={({ message }) => (
                                            <p style={{ marginTop: -1, fontSize: '12px', color: "red" }} className="error-message">{message}</p>
                                        )}
                                    />
                                </Grid>

                                {/* <Grid sx={{ mt: 2 }} item xs={12}>
                                    <SelectX

                                        label={"Choose Task"}
                                        options={fetchTask}
                                        control={control}
                                        error={errors?.task?.id ? errors?.task?.id?.message : false}
                                        error2={errors?.task?.message ? errors?.task?.message : false}
                                        name={'task'}
                                        defaultValue={watch('task')}
                                    />
                                </Grid> */}

                                {/* <Grid sx={{ mt: 2 }} item xs={12}>
                                    <TextInput control={control} name="title"
                                        label="Number Of Stars"
                                        type = 'number'
                                        value={watch('title')} />
                                </Grid> */}
                                 
                                 <Grid sx={{ mt: 2 }} displayPrint={'flex'}  item xs={12}>
                                 <InputLabel sx={{
                                        color: "black",
                                        fontSize: "13px",
                                        pb: .5,
                                        fontWeight: 500
                                    }}>Select Star Rating</InputLabel>
                                 <CustomRatingGreen
                                name="simple-controlled"
                                size='large'
                                  value={starValueGreen}
                                onChange={handleGreenChange}
                                />
                                 <CustomRatingRed
                                 sx={{ml: 10}}
                                name="simple-controlled"
                                size='large'
                                  value={starValueRed}
                                onChange={handleRedChange}
                                // classes={{
                                //     root: classes.root,
                                //   }}
                                />
                                 </Grid>
                               

                                <Grid sx={{ mt: 2 }} item xs={12}>
                                    <InputLabel sx={{
                                        color: "black",
                                        pb: .5
                                    }}>Remarks </InputLabel>
                                    <TextField
                                        {...register('description')}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        sx={{ width: '100%', }}
                                    />
                                </Grid>

                                {/* <Grid container spacing={1} sx={{ mt: 2 }}>
                                    <Grid item xs={6}>
                                        <InputLabel sx={{
                                            color: "black",
                                            pb: .5
                                        }}>Duration </InputLabel>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ display: 'flex', }}>
                                    <Grid item xs={12} sm={6}>
                                        
                                        <TextField
                                            type='number'
                                            control={control}
                                            name="durationHours"
                                            label="Hours"
                                            onChange={handleHourChange}
                                            defaultValue={durationHours}
                                            value={durationHours}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                       
                                        <TextField
                                            type='number'
                                            control={control}
                                            name="durationMinutes"
                                            label="Minutes"
                                            onChange={handleMinuteChange}
                                            defaultValue={durationMinutes}
                                            value={durationMinutes}
                                        />
                                        
                                    </Grid>


                                </Grid> */}


                                <Grid sx={{ mt: 2 }} item xs={12}>
                                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                </Grid>

                            </form>

                        </DialogContent>
                        
                    </>
                ) : (

                    <Box className="loadingScreen" >
                        <LoadingScreen />
                    </Box>


                )}
            </Dialog>
        </div >
    );
};

export default Create;
