import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Backdrop, Button, Card, CircularProgress, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { Activities } from "../../.././../../api/Endpoints/Activities";

const Index = (props) => {

    const [editId, setEditId] = useState();
    const [refresh, setRefresh] = useState(0);
    const handleEdit = (id) => { setRefresh(Math.random); setEditId(parseInt(id)); }

    const columns = [
        {

            field: 'title', headerName: 'title', width: 400,
            renderCell: (params) => <p style={{ cursor: 'pointer' }} onClick={() => ViewTab(params.row.id)}>{params.value}</p>

        },
        {
            field: 'user', headerName: 'User Name', width: 150,
            renderCell: (params) => <p>{params.value?.name}</p>
        },
        {
            field: 'created_at', headerName: 'Created date', width: 150, renderCell: (params) => {
                // console.log("This is data", params);
                // if (params) {

                return moment(params.value).format('DD-MM-YYYY')
                // } else {
                //     return "Not started";
                // }
            }
        },
        {
            field: 'duration', headerName: 'Duration', width: 150,
            renderCell: (params) => <p>{params.row.duration_hours ? params.row.duration_hours == 1 ? params.row.duration_hours + "hr" : params.row.duration_hours + 'hrs' : ''} {params.row.duration_minutes ? params.row.duration_minutes + "mins" : ''}</p>
        },
        {
            field: 'action', headerName: 'Edit', width: 100, valueGetter: (params) => params.row, renderCell: (params) => {
                let id = params.value?.id;
                return <><Button
                    variant='outlined'
                    size={'small'}

                    // style={{ color: 'black' }}
                    onClick={() => handleEdit(id)}>Edit</Button>
                </>;
            }
        },
    ]

    const [list, setList] = useState([]);
    const [listType, setListType] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPreQualifierId, setSelectedPreQualifierId] = useState();
    const [ID, setID] = useState(0)
    const [viewPage, setViewPage] = useState(false)
    const [loading, setLoading] = useState(false);


    const handleListingSearchChange = (keyword) => { setSearchKeyword(keyword); setPageNumber(0); }
    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleLeadClick = (leadId) => { props.onPreQualifierIdChange(leadId); setSelectedPreQualifierId(leadId); }


    const fetchTable = () => {
        setLoading(true);
        Activities.get({ projects_id: props.projectID, keyword: searchKeyword, page: parseInt(pageNumber) + 1, }).then(response => {
            setList(response.data.data);
            setLoading(false);
        })
    }

    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    }

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }
    const ViewTab = (id) => {
        console.log("id here", id);
        setViewPage(true)
        setID(id)
    }
    useEffect(async () => {
        fetchTable();
    }, [searchKeyword, listType, pageNumber])


    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    }


    return (
        <>
            <Card sx={{ p: 2, display: "flex", justifyContent: "space-between", alignItems: 'center' }} variant="outlined">
                <TextField
                    // size='small'
                    style={{ width: 300, marginRight: 10 }}
                    id="outlined-name"
                    label="Search Activity"
                    onChange={handleSearchEntry}

                />
                {/* 
                <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} ProjectID={props.projectID} />

                {viewPage && <DetailView id={ID} handleClose={setViewPage} />} */}


            </Card >


            <Card sx={{ m: 2 }} variant="outlined">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                {typeof list.data === "object" &&
                    <DataGrid
                        paginationMode="server"
                        rows={list?.data}
                        columns={columns}
                        page={pageNumber}
                        pageSize={10}
                        rowHeight={20}
                        rowsPerPageOptions={[10]}
                        rowCount={list.total}
                        onPageChange={handlePageChange}
                        autoHeight
                        density={"comfortable"}
                    />
                }
            </Card>
        </>
    );
};

export default Index;
