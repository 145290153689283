import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import PageHeader from '../common/page-header';
import Create from './Create';
import Details from './Details'
function Index() {
    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    };
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState(undefined);
    const [refresh, setRefresh] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [DetaiViewID, setDetailViewiD] = useState(0)
    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random);
    };
    const DetailView = (id) => {
        setDetailViewiD(2)
    }
    const DetailsClose = () => {
        setDetailViewiD(undefined)
    }
    const rows = [
        { title: 'Test Dashboard', createdBy: 'John Doe', createdDate: '10-01-2024' },

        // Add more rows as needed
    ];

    return (
        <>
            {!DetaiViewID ? (
                <>
                    <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
                        <PageHeader title={"My Dashboards"} />
                        <Create
                            key={refresh}
                            onNew={handleCreate}
                            onUpdate={handleUpdate}
                            editId={editId}
                            setEditId={setEditId}
                        />
                    </Box >

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>title</TableCell>
                                    <TableCell>Created By</TableCell>
                                    <TableCell>Created Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell onClick={() => DetailView(row.id)} sx={{ cursor: 'pointer' }}>{row.title}</TableCell>
                                        <TableCell>{row.createdBy}</TableCell>
                                        <TableCell>{row.createdDate}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <Details onClose={DetailsClose} />
            )
            }

        </>
    );
}

export default Index;
