import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, Button, Box } from '@mui/material';
import Create from './Create';

function Index({ onClose }) {
    const data = [
        {
            title: 'Card 1',
            type: 'Project',
            priority: 'Low',
            createdDate: '10-07-2023',
        },
        { title: 'Card 2', type: 'Member', priority: 'Low', createdDate: '10-06-2024' },

    ];
    const handleUpdate = () => {
        setPageNumber();
        setPageNumber(0);
    };
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState(undefined);
    const [refresh, setRefresh] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [DetaiViewID, setDetailViewiD] = useState(0)
    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random);
    };
    const DetailView = (id) => {
        setDetailViewiD(2)
    }
    const DetailsClose = () => {
        setDetailViewiD(undefined)
    }
    return (
        <>
            {/* <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end", mt: 5, ml: 4 }}>

                <Create
                    key={refresh}
                    onNew={handleCreate}
                    onUpdate={handleUpdate}
                    editId={editId}
                    setEditId={setEditId}
                />
            </Box > */}

            <Grid container spacing={2}>

                {data.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card variant="outlined">
                            <CardContent sx={{ p: 2 }}>
                                <Typography variant="h6" component="div" sx={{ mt: 1 }}>
                                    {item.title}
                                </Typography>
                                <Typography color="textSecondary" sx={{ mt: 1 }} >
                                    Type: {item.type}
                                </Typography>
                                <Typography color="textSecondary" sx={{ mt: 1 }} >
                                    Priority: {item.priority}
                                </Typography>
                                <Typography color="textSecondary" sx={{ mt: 1 }} >
                                    Created Date: {item.createdDate}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
                {/* Button Grid */}
                <Grid item xs={12} container justifyContent="flex-end">
                    <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={onClose}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default Index;
