import React, { useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
} from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { DashboardSidebarItem } from "./dashboard-sidebar-item";
import { Scrollbar } from "./scrollbar";
import { ChevronLeft as ChevronLeftIcon } from "../icons/chevron-left";
import { ChevronRight as ChevronRightIcon } from "../icons/chevron-right";
import PaidIcon from "@mui/icons-material/Paid";
import { Link } from "react-router-dom";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import AddLinkIcon from "@mui/icons-material/AddLink";
import PaymentsIcon from "@mui/icons-material/Payments";
import BusinessIcon from "@mui/icons-material/Business";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import EngineeringIcon from "@mui/icons-material/Engineering";
import StarIcon from "@mui/icons-material/Star";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";

import {
  AccessAlarm,
  AccountBalance,
  AccountTree,
  AdminPanelSettings,
  Assessment,
  AssignmentInd,
  BarChart,
  BorderColor,
  CalendarMonth,
  ChecklistRtl,
  CoPresent,
  ConnectWithoutContact,
  CreateNewFolder,
  Dashboard,
  DesignServices,
  DocumentScanner,
  Filter1Outlined,
  FlightTakeoff,
  Group,
  Groups,
  Groups3,
  LaptopMac,
  ListAlt,
  ManageAccounts,
  MonetizationOn,
  MoreTime,
  NoteAlt,
  Photo,
  RssFeedRounded,
  Settings,
  SpaceDashboard,
  StickyNote2,
  Summarize,
  Task,
  Topic,
  TravelExplore,
  ViewAgenda,
  ViewCarousel,
  ViewCarouselOutlined,
  ViewListOutlined,
  Work,
  YoutubeSearchedForOutlined,
  ArchiveOutlined,
} from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ArticleIcon from "@mui/icons-material/Article";
import { InputLabel, Typography } from "@mui/material";
import { Users } from "../api/Endpoints/Users";
import { useAuth } from "../hooks/use-auth";
import { ListAltRounded } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
const items = [
  {
    icon: ArticleIcon,
    title: "Dash Board",
    href: "/dashboard",
    submenu: [
      {
        icon: Dashboard,
        title: "Work Board",
        href: "/dashboard/workboard",
      },
      {
        icon: Dashboard,
        title: "Department Dashboard",
        href: "/dashboard/department-dashboard",
      },
      {
        icon: Dashboard,
        title: "My Dashboards",
        href: "/dashboard/my-dashboards",
      },
    ],
  },
  {
    icon: ArticleIcon,
    title: "Project Management",
    href: "/dashboard/project-management",
    submenu: [
      {
        icon: YoutubeSearchedForOutlined,
        title: "Projects",
        href: "/dashboard/project-management/projects",
      },
      {
        icon: Filter1Outlined,
        title: "Tasks",
        href: "/dashboard/project-management/tasks",
      },
      {
        icon: ViewListOutlined,
        title: "Activities",
        href: "/dashboard/project-management/activities",
      },
      {
        icon: StickyNote2,
        title: "Work Plan",
        href: "/dashboard/project-management/work-plan",
      },
      {
        icon: CheckCircleOutlineIcon,
        title: "Checklist",
        href: "/dashboard/project-management/check-list",
      },
      {
        icon: LaptopMac,
        title: "Meetings",
        href: "/dashboard/project-management/meetings",
      },
      {
        icon: ArchiveOutlined,
        title: "Archived Tasks",
        href: "/dashboard/project-management/archived-tasks",
      },
    ],
  },

  {
    icon: BusinessIcon,
    title: "Company",
    href: "/dashboard/company",
    submenu: [
      {
        icon: Groups3,
        title: "Employees",
        href: "/dashboard/company/employees",
      },
      {
        icon: EmojiFlagsIcon,
        title: "Targets & Goals",
        href: "/dashboard/company/employees/target",
      },
      {
        icon: BusinessIcon,
        title: "Departments",
        href: "/dashboard/company/departments",
      },
      {
        icon: DocumentScanner,
        title: "Documents",
        href: "/dashboard/company/document",
      },
      {
        icon: DocumentScannerIcon,
        title: "Complaints",
        href: "/dashboard/company/complaints",
      },
      {
        icon: EmojiEventsIcon,
        title: "Awards",
        href: "/dashboard/company/awards",
      },
      {
        icon: StarIcon,
        title: "Star Rating",
        href: "/dashboard/company/star-rating",
      },
    ],
  },

  // Submenu Project
  // {
  //   icon: AccountTree,
  //   title: 'Project Management',
  //   href: '/dashboard/project',
  //   submenu: [
  //     {
  //       icon: Assessment,
  //       title: 'Projects',
  //       href: '/dashboard/project',
  //     },
  //     {
  //       icon: Task,
  //       title: 'Tasks',
  //       href: '/dashboard/project/task',
  //     },
  //     {
  //       icon: AccessAlarm,
  //       title: 'Activities',
  //       href: '/dashboard/project/activities',
  //     },
  //     {
  //       icon: LaptopMac,
  //       title: 'Meeting',
  //       href: '/dashboard/project/meeting',
  //     },
  //     {
  //       icon: Work,
  //       title: 'Work Request',
  //       href: '/dashboard/project/work-request',
  //     },
  //     {
  //       icon: StickyNote2,
  //       title: 'Work Plan',
  //       href: '/dashboard/project/work-plan',
  //     },
  //   ],
  // },

  // // Sub Menu Client
  // {
  //   icon: Groups,
  //   title: 'Clients',
  //   href: '/dashboard/client',
  //   submenu: [
  //     {
  //       icon: Group,
  //       title: 'Clients',
  //       href: '/dashboard/client',
  //     },
  //     {
  //       icon: AssignmentInd,
  //       title: 'Clients Report',
  //       href: '/dashboard/client/client-report',
  //     },
  //   ]
  // },

  // // Sub Menu SEO
  // {
  //   icon: TravelExplore,
  //   title: 'SEO',
  //   href: '/dashboard/Backlink-Sites',
  //   submenu: [
  //     {
  //       icon: Tab,
  //       title: 'Backlink Sites',
  //       href: '/dashboard/Backlink-Sites',
  //     },
  //     {
  //       icon: Link,
  //       title: 'Backlink',
  //       href: '/dashboard/Backlink-Sites',
  //     },
  //   ]
  // },

  // {
  //   icon: ConnectWithoutContact,
  //   title: 'Social Media',
  //   href: '/dashboard/social-media',
  //   submenu: [
  //     {
  //       icon: CalendarMonth,
  //       title: 'Creative Calender',
  //       href: '/dashboard/social-media',
  //     },
  //     {
  //       icon: DesignServices,
  //       title: 'Design',
  //       href: '/dashboard/social-media/design',
  //     },
  //   ]
  // },
  // {
  //   icon: AdminPanelSettings,
  //   title: 'HR',
  //   href: '/dashboard/hr',
  //   submenu: [
  //     {
  //       icon: CoPresent,
  //       title: 'Daily Attendence',
  //       href: '/dashboard/hr',
  //     },
  //     {
  //       icon: ChecklistRtl,
  //       title: 'Attendence Report',
  //       href: '/dashboard/hr/attendence-report',
  //     },
  //     {
  //       icon: Topic,
  //       title: 'Documents',
  //       href: '/dashboard/hr/documents',
  //     },
  //     {
  //       icon: ManageAccounts,
  //       title: 'Edit Profile',
  //       href: '/dashboard/hr/edit-profile',
  //     },
  //     {
  //       icon: SpaceDashboard,
  //       title: 'Departments',
  //       href: '/dashboard/hr/departments',
  //     },
  //   ]
  // },
  // {
  //   icon: AccountBalance,
  //   title: 'Accounts',
  //   href: '/dashboard/accounts',
  //   submenu: [
  //     {
  //       icon: BorderColor,
  //       title: 'Work Orders',
  //       href: '/dashboard/accounts',
  //     },
  //     {
  //       icon: MonetizationOn,
  //       title: 'Payments',
  //       href: '/dashboard/accounts/payments',
  //     },
  //   ]
  // },

  {
    icon: ViewCarouselOutlined,
    title: "Tasks Cards",
    href: "/dashboard/task-card",
  },

  {
    icon: Photo,
    title: "Content Management",
    href: "/dashboard/content-management",
    submenu: [
      {
        icon: CalendarMonthOutlinedIcon,
        title: "Creative Calendar",
        href: "/dashboard/content-management/creative-calendar",
      },
      {
        icon: Photo,
        title: "Creative Works",
        href: "/dashboard/content-management/creative-works",
      },
      {
        icon: CreateNewFolder,
        title: "Creative Requirments",
        href: "/dashboard/content-management/creative-requirments",
      },
      {
        icon: NoteAlt,
        title: "Content Writing",
        href: "/dashboard/content-management/content-writing",
      },
      {
        icon: VideoCameraBackIcon,
        title: "Video Shoots",
        href: "/dashboard/content-management/video-shoots",
      },
    ],
  },

  {
    icon: LaptopMac,
    title: "Learning Material",
    href: "/dashboard/learning-material",
  },
  {
    icon: AssignmentTurnedInOutlinedIcon,
    title: "Work Request",
    href: "/dashboard/work-request",
  },
  {
    icon: MoreTime,
    title: "Scheduled Task",
    href: "/dashboard/schedule-task",
  },

  {
    icon: Settings,
    title: "Clients",
    href: "/dashboard/clients",
  },

  {
    icon: ListAlt,
    title: "Work Orders",
    href: "/dashboard/Work-orders",
  },

  {
    icon: ListAltRounded,
    title: "Attendance",
    href: "/dashboard/attendance",
    submenu: [
      {
        icon: ListAltRounded,
        title: "Daily Attendance",
        href: "/dashboard/attendance/daily",
      },
      {
        icon: ListAltRounded,
        title: "Attendance Report",
        href: "/dashboard/attendance/report",
      },
      {
        icon: FlightTakeoff,
        title: "Leave",
        href: "/dashboard/attendance/leave",
      },
      {
        icon: FlightTakeoff,
        title: "Leave Eligibility",
        href: "/dashboard/attendance/leave-eligibility",
      },
    ],
  },
  {
    icon: ArticleIcon,
    title: "SEO",
    href: "/dashboard/seo",
    submenu: [
      {
        icon: AddLinkIcon,
        title: "Backlink Sites",
        href: "/dashboard/seo/Backlink-Sites",
      },
      {
        icon: AddLinkIcon,
        title: "Backlinks",
        href: "/dashboard/seo/back-links",
      },
    ],
  },

  {
    icon: ArticleIcon,
    title: "Website Projects",
    href: "/dashboard/website-projects",
  },
  {
    icon: PaletteOutlinedIcon,
    title: "Designs",
    href: "/dashboard/designs",
  },

  // {
  //   icon: PaidIcon,
  //   title: 'Reimbursement',
  //   href: '/dashboard/reimbursement',
  // },
  {
    icon: PaidIcon,
    title: " Payments",
    href: "/dashboard/vendor-payments",
  },

  {
    icon: Summarize,
    title: "Reports",
    href: "/dashboard/reports",
    submenu: [
      {
        icon: ViewListOutlined,
        title: "Activity Reports",
        href: "/dashboard/reports/activity",
      },
      {
        icon: ViewListOutlined,
        title: "Backlink Reports",
        href: "/dashboard/reports/backlink",
      },
    ],
  },

  {
    icon: Summarize,
    title: "Client Reports",
    href: "/dashboard/client-report",
  },
  {
    icon: RssFeedRounded,
    title: "Website Live Tracker",
    href: "website-live-tracker",
  },
  {
    icon: PersonIcon,
    title: "Edit Profile",
    href: "/dashboard/edit-profile",
  },
  {
    icon: ViewAgenda,
    title: "Projects Card",
    href: "/dashboard/projects-cards",
  },
  {
    icon: EngineeringIcon,
    title: "Under Development",
    href: "/dashboard/under-development",
    submenu: [
      {
        icon: Dashboard,
        title: "Performance Dashboard",
        href: "/dashboard/under-development/Performance-dashboard",
      },
    ],
  },

  // {
  //   icon: PersonIcon,
  //   title: 'Sign Out',
  //   href: '/dashboard/signout',
  // },
];

export const DashboardSidebar = (props) => {
  const { onPin, pinned } = props;
  const { pathname } = useLocation();
  const [openedItem, setOpenedItem] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [activeHref, setActiveHref] = useState("");
  const [openDropdown, setOpenDropdown] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [userData, setUserData] = useState();
  const auth = useAuth();
  const handleOpenItem = (item) => {
    if (openedItem === item) {
      setOpenedItem(null);
      return;
    }

    setOpenedItem(item);
  };

  const fetchUserData = () => {
    Users.me().then((response) => {
      setUserData(response.data.data);
    });
  };

  const logoutHandler = () => {
    auth.logout();
  };

  const expandHandler = () => {
    onPin();
  };
  const handleDropdownToggle = () => {
    setOpenDropdown(!openDropdown);
  };

  useEffect(() => {
    fetchUserData();
    items.forEach((item) => {
      if (item.items) {
        for (let index = 0; index < item.items.length; index++) {
          const active = matchPath(
            { path: item.items[index].href, end: true },
            pathname
          );

          if (active) {
            setActiveItem(item);
            setActiveHref(item.items[index].href);
            setOpenedItem(item);
            break;
          }
        }
      } else {
        const active = !!matchPath({ path: item.href, end: true }, pathname);

        if (active) {
          setActiveItem(item);
          setOpenedItem(item);
        }
      }
    });
  }, [pathname]);

  return (
    <Drawer
      open
      className={pinned ? "sidebar-expand" : null}
      sx={{ zIndex: 1000 }}
      variant="permanent"
      PaperProps={{
        // onMouseOver: () => {
        //   setHovered(true);
        // },
        // onMouseLeave: () => {
        //   setHovered(false);
        // },
        sx: {
          // backgroundColor: "background.paper",

          backgroundColor: '#2d2638',
          height: "calc(100% - 64px)",
          overflowX: "hidden",
          transition: "width 250ms ease-in-out",
          width: pinned ? 270 : 73,

          "& .simplebar-content": {
            height: "100%",
          },
          "&:hover": {
            width: 270,
            "& span, p": {
              display: "flex",
            },
          },
        },
      }}
    >
      <Scrollbar
        style={{
          display: "flex",
          flex: 1,
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            p: 2,
          }}
        >
          {
            <img
              className={"logo-image"}
              src="https://www.spiderworks.in/theme/web/img/logo-sw.png"
              alt="SpiderWorks logo"
            />
          }

          <div className="logout">
            {userData?.name} | <Button onClick={logoutHandler}>Logout</Button>
          </div>

          <List disablePadding>
            {items.map((item) => (
              // <React.Fragment key={item.title}>
              //   <Link to={item.href} style={{ textDecoration: 'none', color: 'black', justifyContent: 'flex-start', whiteSpace: 'nowrap' }}>
              //     <ListItem
              //       sx={{ backgroundColor: activeItem?.title === item.title ? 'rgb(234,248,244)' : '', borderRadius: 2 }}

              //     >

              //       <ListItemIcon className="custom-list-icon">
              //         <item.icon className='dash-icons' sx={{ color: activeItem?.title === item.title ? 'rgb(63,197,149)' : 'rgb(111,132,143)' }} />
              //       </ListItemIcon>
              //       <ListItemText>
              //         <Typography style={{ fontWeight: 500, fontSize: '14px', color: activeItem?.title === item.title ? 'black' : 'rgb(103,123,133)' }}>
              //           {item.title}
              //         </Typography>
              //       </ListItemText>

              //     </ListItem>
              //   </Link>

              // </React.Fragment>
              <React.Fragment key={item.title}>
                <Link
                  to={item.href}
                  style={{
                    textDecoration: "none",
                    color: "rgb(133,150,160)",
                    justifyContent: "flex-start",
                    whiteSpace: "nowrap",
                  }}
                >
                  <ListItem
                    sx={{
                      backgroundColor:
                        activeItem?.title === item.title
                          ? "rgb(234,248,244)"
                          : "",
                      borderRadius: 2,
                    }}
                    onClick={item.submenu ? handleDropdownToggle : null}
                  >
                    <ListItemIcon className="custom-list-icon">
                      <item.icon
                        className="dash-icons"
                        sx={{
                          color:
                            activeItem?.title === item.title
                              ? "rgb(63,197,149)"
                              : "rgb(111,132,143)",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: "14px",
                          color:
                            activeItem?.title === item.title
                              ? "rgb(63,197,149)"
                              : "rgb(103,123,133)",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </ListItemText>
                    {/* Down arrow button */}

                    {item.submenu ? (
                      openDropdown && activeItem?.title === item.title ? (
                        <ArrowDropUpIcon onClick={handleDropdownToggle} />
                      ) : (
                        <ArrowDropDownIcon onClick={handleDropdownToggle} />
                      )
                    ) : null}
                  </ListItem>
                </Link>
                {/* Render submenu items */}
                {openDropdown &&
                  activeItem?.title === item.title &&
                  item.submenu ? (
                  <List
                    disablePadding
                    sx={{
                      backgroundColor: "background.paper",
                      borderBottomRightRadius: 5,
                      borderBottomLeftRadius: 5,
                      bottom: 7,
                      position: "relative",
                      paddingTop: 1,
                      paddingLeft: 2,
                    }}
                  >
                    {item.submenu.map((dropdownItem) => (
                      <Link
                        class="nav-list"
                        Link
                        to={dropdownItem.href}
                        key={dropdownItem.title}
                        style={{
                          textDecoration: "none",
                          color: "rgb(133,150,160)",
                          justifyContent: "flex-start",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <ListItem
                          sx={{
                            color:
                              activeItem?.title === item.title ? "blue" : "",
                          }}
                        >
                          <ListItemIcon className="">
                            <dropdownItem.icon
                              className=""
                              sx={{
                                color:
                                  activeItem?.title === dropdownItem.title
                                    ? "rgb(63,197,149)"
                                    : "rgb(103,123,133)",
                                fontSize: "20px",
                              }}
                            />
                          </ListItemIcon>

                          <Typography
                            style={{
                              color:
                                pathname === dropdownItem.href
                                  ? "rgb(63,197,149)"
                                  : "rgb(103,123,133)",
                              fontSize: "14px",
                              marginLeft: -5,
                            }}
                          >
                            {dropdownItem.title}
                          </Typography>
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                ) : null}
              </React.Fragment>
            ))}
            <Link
              to="/dashboard/report-issue"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <InputLabel
                sx={{
                  mt: 10,
                  fontSize: "14px",
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                  "&:hover": {
                    color: "red",
                  },
                }}
              >
                Report an issue
              </InputLabel>
            </Link>
          </List>

          <Box sx={{ flexGrow: 1 }} />
          <Divider />
          <Box sx={{ pt: 1 }}>
            <IconButton onClick={expandHandler}>
              {pinned ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Box>
        </Box>
      </Scrollbar>
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onPin: PropTypes.func,
  pinned: PropTypes.bool,
};
