import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogContent, DialogTitle, Grid, Slide } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TextInput from '../../form/TextInput';
import { useForm } from 'react-hook-form';

function Create(props) {
    const { register, handleSubmit, watch, formState: { errors }, control, Controller, setValue, getValues, reset } = useForm({})
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => { props.onNew(); };

    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            setOpen(true);
        } else if (props.editId === 0) {
            setOpen(true);
        }
    }, [props.editId])

    const handleClose = () => {
        props.setEditId(undefined)
        setOpen(false);
    };

    return (
        <>
            <Button variant="contained" onClick={handleClickOpen}>
                Create Dashboard
            </Button>
            {open &&

                <Dialog
                    open={open}
                    PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"

                >
                    <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Project" : "Create Dashboard"}</DialogTitle>
                    <DialogContent>
                        <form >


                            <Grid container spacing={2} sx={{ display: 'flex', mt: 1 }}>
                                <Grid item xs={12}>
                                    <TextInput
                                        control={control}
                                        name="title"
                                        label="Dashboard Name"
                                        value={watch('title')}
                                    />
                                </Grid>





                                <Grid sx={{ p: 1 }} mb={5} item xs={12} justifyContent={'end'} display={'flex'}>
                                    <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                        variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog >
            }

        </>
    )
}

export default Create