import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Card,
    Backdrop,
    CardContent,
    Typography,
    Grid,
    Divider,
    Select,
    MenuItem,
    DialogTitle,
    InputLabel,
    TextareaAutosize,
    TextField,
    Slide,
    CircularProgress,
    Box,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
} from "@mui/material";
import { Project } from '../../../../api/Endpoints/Project';
import { useState } from 'react';
import Create from '../create';
import { Cancel, CheckCircle } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(1, 0),
    },
    select: {
        minWidth: 200,
    },
}));

const Details = ({ projectID, setProjectName, getScope }) => {
    const classes = useStyles();



    const [Loading, setLoading] = useState(false)
    const [Data, setData] = useState([])
    const [refresh, setRefresh] = useState(0)

    const [editId, setEditId] = useState()


    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random)
    }
    const fetchDetails = () => {
        setLoading(true);
        Project.getDetails({ project_id: projectID }).then(response => {
            setData(response.data.data);
            setProjectName(response.data.data.name)
            let website = response.data.data.scopes.find(item => item.scope == 'Website Development')
            getScope(website)
            console.log(response.data.data);
            setLoading(false);
        })
    }









    const Scopes = [
        { name: 'Website Development' },
        { name: 'SEO' },
        { name: 'Influencer Marketing' },
        { name: 'Social Media Management' },
        { name: 'Paid Ads' },
        { name: 'Video Shoot' },
        { name: 'App Development' },
        { name: 'IT Managemenet' },
        { name: 'Branding' },
    ]
    const Schedule = [
        { name: '30/10/2024-Website Development' },
        { name: '01/05/2024-Influencer Marketing' },
        { name: '08/09/2024-Branding' },
    ]


    useEffect(() => {
        fetchDetails()
    }, [])
    // console.log(editId);
    return (
        <>
            {
                editId &&
                <a hidden>
                    <Create key={refresh} onNew={handleCreate} onUpdate={fetchDetails} editId={editId} setEditId={setEditId} />

                </a>}
            {Loading ? (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={Loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <>
                    <div style={{ textAlign: 'end', marginRight: 5 }}><Button onClick={() => setEditId(projectID)} variant='contained'>Edit</Button></div>
                    <Card className={classes.card}>
                        <Grid sm={12} className='test'>
                            <Grid container spacing={2}>
                                <Grid item xs={3} sm={3}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Client :
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data.account?.account_name}
                                        </Typography>
                                    </Typography>

                                </Grid>
                                {/* <Grid item xs={3} sm={3}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Started Date :
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.start_date ? new Date(Data.start_date).toLocaleDateString("en-GB") : ""}
                                        </Typography>
                                    </Typography>

                                </Grid> */}
                                <Grid item xs={3} sm={3} >
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Started Date :
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.start_date ? new Date(Data.start_date).toLocaleDateString("en-GB") : ""}
                                        </Typography>
                                    </Typography>

                                </Grid>
                                <Grid item xs={3} sm={3}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        State:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            Open
                                        </Typography>
                                    </Typography>

                                </Grid>
                                <Grid item xs={3} sm={3}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Hours :
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            180
                                        </Typography>
                                    </Typography>

                                </Grid>

                            </Grid>

                            <Divider className={classes.divider} sx={{ pt: 2 }} />

                            {/* <Grid container spacing={2}>

                            </Grid>

                            <Divider className={classes.divider} sx={{ pt: 2 }} /> */}
                            <Grid container spacing={2} sm={12}>
                                <Grid className='test' p={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Scope:
                                    </Typography>
                                    <div style={{ height: '200px', overflowY: 'auto' }}>
                                        {Scopes.map((item) => (
                                            <Grid container key={item.name} p={1}>
                                                <Grid item >
                                                    {Data?.scopes?.find((scope) => scope?.scope === item.name) ? (
                                                        <CheckCircle fontSize="small" style={{ color: 'green' }} />
                                                    ) : (
                                                        <Cancel fontSize="small" style={{ color: 'red' }} />
                                                    )}
                                                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                                        {item.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </div>

                                </Grid>
                                <Grid className='test' p={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Schedule:
                                    </Typography>
                                    <div style={{ height: '200px', overflowY: 'auto' }}>
                                        {Schedule.map((item) => (
                                            <Grid container p={1} key={item.name}>
                                                <Grid item >
                                                    {Data?.scopes?.find((scope) => scope?.scope === item.name) ? (
                                                        <CheckCircle fontSize="small" style={{ color: 'green' }} />
                                                    ) : (
                                                        <Cancel fontSize="small" style={{ color: 'red' }} />
                                                    )}
                                                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                                        {item.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </div>

                                </Grid>
                                <Grid className='test' p={2} lg={3}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Open Tasks:
                                    </Typography>
                                    <div style={{ height: '200px', overflowY: 'auto' }}>
                                        <TableContainer component={Paper} style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                            <Table stickyHeader>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography variant="body2" style={{ fontSize: '16px' }}>
                                                                test Task
                                                            </Typography>

                                                        </TableCell>

                                                    </TableRow>
                                                    {/* Add more rows as needed */}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </Grid>
                                <Grid className='test' p={2} lg={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Recent Activities:
                                    </Typography>
                                    <div style={{ height: '200px', overflowY: 'auto' }}>
                                        <TableContainer component={Paper} style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                            <Table stickyHeader>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography variant="body2" style={{ fontSize: '16px' }}>
                                                                test Actvitiy
                                                            </Typography>

                                                        </TableCell>

                                                    </TableRow>
                                                    {/* Add more rows as needed */}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>

                                </Grid>
                                <Grid className='test' p={2} lg={3}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Notes:
                                    </Typography>
                                    <div style={{ height: '200px', overflowY: 'auto' }}>
                                        <TableContainer component={Paper} style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                            <Table stickyHeader>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography variant="body2" style={{ fontSize: '16px' }}>
                                                                test Note
                                                            </Typography>

                                                        </TableCell>

                                                    </TableRow>
                                                    {/* Add more rows as needed */}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>

                                </Grid>  <Grid className='test' p={2} lg={3} sm={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Deliverables:
                                    </Typography>
                                    <div style={{ height: '100px', overflowY: 'auto' }}>
                                        <TableContainer hantainer component={Paper} style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                            <Table stickyHeader>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography variant="body2" style={{ fontSize: '16px' }}>
                                                                Test Deliverables
                                                            </Typography>

                                                        </TableCell>

                                                    </TableRow>
                                                    {/* Add more rows as needed */}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>

                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} mt={2}>
                                <Typography variant="body1" component="p" style={{ fontWeight: '500', fontSize: '1.0rem' }}>
                                    Scope Details :
                                </Typography>
                            </Grid>

                            <Divider className={classes.divider} sx={{ pt: 2 }} />
                            <Grid container spacing={2} >
                                <Grid item xs={6} sm={6} mt={2}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                        Country  :
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.country?.name}
                                        </Typography>
                                    </Typography>


                                </Grid>

                            </Grid>

                            <Divider className={classes.divider} sx={{ pt: 2 }} />
                            <Grid item xs={12} sm={12} mt={2}>
                                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.0rem' }}>
                                    Description :
                                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                        {Data?.description}

                                    </Typography>

                                </Typography>


                            </Grid>
                        </Grid>
                    </Card >

                </>
            )
            }
        </>
    );

};

export default Details;
