import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Backdrop, Button, Card, CircularProgress, FormControl, Grid, MenuItem, Popover, Select, TextField } from "@mui/material";
import Details from './Tabs/Details'
import AddTeamMember from './actions/addTeamMember';
import Addmembers from './actions';
import Documents from './Tabs/Documents/Documents';
import Notes from './Tabs/Notes'
import Tasks from './Tabs/Tasks'
import WorkRequests from './Tabs/workRequests'
import { useEffect } from 'react';
import { Project } from '../../../api/Endpoints/Project';
import { useState } from 'react';
import Activity from './Tabs/activity'
import Meetings from './Tabs/Meetings'
import { ArrowDropDown, PinDrop } from '@material-ui/icons';
import Calender from './Tabs/Calender';
import Keywords from './Tabs/Keywords';
import WorkOrders from './Tabs/WorkOrders';
import Invoices from './Tabs/Invoices';
import SiteVisits from './Tabs/SiteVisits';
import Reports from './Tabs/Reports';
import { Close } from '@mui/icons-material';
import WebsiteDevelopment from './Tabs/websiteDevelopment/WebsiteDevelopment';
import { Task } from '../../../api/Endpoints/Task';
import { WorkRequest } from '../../../api/Endpoints/WorkRequest';
import Deliverables from './Tabs/Deliverables/index';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs({ CloseTab, projectID, fetchTable, setSearchKeyword }) {
    const [value, setValue] = React.useState(0);
    const [ProjectName, setProjectName] = useState('')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [scope, setScope] = useState()
    const [stickyNote, setStickyNote] = useState([])



    const [eightTab, setEightTab] = useState({
        label: 'Document',
        id: scope ? 8 : 7
    })

    useEffect(() => {
        fetchDetails();
    }, [projectID]);


    const fetchDetails = () => {
        Project.getDetails({ project_id: projectID }).then(response => {
            const project = response.data.data.name
            setProjectName(project)
            setStickyNote(response?.data?.data?.notes)
        })
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setSearchKeyword()
        fetchTable()
        CloseTab()
    }

    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverChange = (data) => {
        console.log(data);
        setEightTab({
            label: data.label,
            id: data.id
        });
        setValue(data.id)
        handleClosePopover()
    };
    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const id = anchorEl ? 'popover-basic' : undefined;

    const MenuTabs = [

        { label: "Document", id: scope ? 8 : 7 },
        { label: "Calendar", id: scope ? 8 : 7 },
        { label: "Keywords", id: scope ? 8 : 7 },
        { label: "Work Orders", id: scope ? 8 : 7 },
        { label: "Invoices", id: scope ? 8 : 7 },
        { label: "Site Visits", id: scope ? 8 : 7 },
        { label: "Reports", id: scope ? 8 : 7 },
        { label: "Deliverables ", id: scope ? 8 : 7 },
    ];

    const getScope = (data) => {
        console.log(data);
    }
    const [taskCount, setTaskCount] = useState(0)
    const [noteCount, setNoteCount] = useState(0)
    const [memberCount, setMemberCount] = useState(0)
    const [workRcount, setWorkRcount] = useState(0)
    const fetchTaskCount = () => {
        Task.get({ projects_id: projectID }).then(response => {
            // console.log(response.data.data);
            setTaskCount(response.data.data.total);
        }).catch(error => {
            console.log(error);
        })
    }
    const fetchNoteCount = async () => {
        const response = await Project.getNotes({ projects_id: projectID })
        if (response.data.status === "success") {
            // console.log(response.data.data);
            setNoteCount(response.data.data.total)
        }
    }
    const fetchMemberCount = async () => {
        try {
            const response = await Project.getEmployees({ projects_id: projectID });
            if (response.data.status === "success") {
                setMemberCount(response?.data?.data?.total)
            }
        } catch (error) {
            console.error("Error fetching list:", error);
        }
    };
    const fetchWorkRcount = () => {
        WorkRequest.get({ projects_id: projectID }).then(response => {
            setWorkRcount(response.data.data.total);
        })
    }

    useEffect(() => {
        fetchTaskCount()
        fetchNoteCount()
        fetchMemberCount()
        fetchWorkRcount()
    }, [])

    console.log(stickyNote);
    return (
        <>

            <div className='projectdetail-block'>
                <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid>
                        {ProjectName ? <h3 style={{ marginLeft: 5 }}>Project :  {ProjectName}</h3> : null}
                    </Grid>

                    <a onClick={handleClose} style={{ marginRight: 15, cursor: 'pointer', color: 'grey' }}><Close fontSize='small' /></a>
                </Grid>
                <Grid ml={1}>
                    {
                        stickyNote?.map((note, index) => (
                            <a key={index}>
                                <a style={{ color: 'grey' }}>{note?.title}</a>
                                <br />
                            </a>
                        ))
                    }
                </Grid>

                <Box sx={{ width: '100%', mt: 1 }}>
                    <Box display={'flex'} alignItems={'center'} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ ml: 2 }}>
                            <Tab label="Details" {...a11yProps(0)} />
                            {scope &&
                                <Tab label="Website Management" {...a11yProps(1)} />
                            }
                            <Tab label={`Work Requests (${workRcount})`} {...a11yProps(2)} />
                            <Tab label={`Tasks (${taskCount})`} {...a11yProps(3)} />
                            <Tab label="Activities" {...a11yProps(4)} />
                            <Tab label="Meetings" {...a11yProps(5)} />
                            <Tab label={`Notes (${noteCount})`} {...a11yProps(6)} />
                            <Tab label={`Members (${memberCount})`} {...a11yProps(7)} />

                            {/* // eightTab.id == 7 &&
                                <Tab label="Documents" {...a11yProps(7)} /> */}

                            <Tab label={eightTab.label} {...a11yProps(8)} />


                            {/* {
                                eightTab.id == 8 &&
                                <Tab label="Calendar " {...a11yProps(8)} />
                            }
                            {
                                 eightTab.id == 9 &&
                                <Tab label="Keywords " {...a11yProps(9)} />
                            } */}
                            {/* <Tab label="Work Orders" {...a11yProps(10)} /> */}
                            {/* <Tab label="Invoices" {...a11yProps(11)} /> */}
                            {/* <Tab label="Site Visits" {...a11yProps(12)} /> */}
                            {/* <Tab label="Leads " {...a11yProps(13)} /> */}
                            {/* <Tab label="Scop of Work" {...a11yProps(13)} /> */}

                        </Tabs>
                        <ArrowDropDown
                            style={{ cursor: 'pointer' }}
                            onClick={handleOpenPopover}
                            aria-describedby={id}
                        />
                        <Popover
                            id={id}
                            open={anchorEl}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >{
                                MenuTabs.map((menus) => (
                                    <MenuItem onClick={() => handlePopoverChange(menus)}>{menus.label}</MenuItem>
                                ))
                            }
                        </Popover>

                    </Box>
                    <TabPanel value={value} index={0}>
                        <Details scope={scope} getScope={setScope} setProjectName={setProjectName} projectID={projectID} />
                    </TabPanel>
                    {
                        scope &&
                        <TabPanel value={value} index={1}>
                            <WebsiteDevelopment projectID={projectID} />
                        </TabPanel>
                    }
                    <TabPanel value={value} index={scope ? 2 : 1}>
                        <WorkRequests setCount={setWorkRcount} projectID={projectID} />
                    </TabPanel>
                    <TabPanel value={value} index={scope ? 3 : 2}>
                        <Tasks setCount={setTaskCount} projectID={projectID} />
                    </TabPanel>
                    <TabPanel value={value} index={scope ? 4 : 3}>
                        <Activity projectID={projectID} />
                    </TabPanel>
                    <TabPanel value={value} index={scope ? 5 : 4}>
                        <Meetings projectID={projectID} fetchTable={fetchTable} />
                    </TabPanel>
                    <TabPanel value={value} index={scope ? 6 : 5}>
                        <Notes fetch={fetchDetails} setCount={setNoteCount} projectID={projectID} />
                    </TabPanel>
                    <TabPanel value={value} index={scope ? 7 : 6}>
                        <Addmembers setCount={setMemberCount} projectID={projectID} />
                    </TabPanel>
                    <TabPanel value={value} index={scope ? 8 : 7}>
                        {
                            eightTab.label == 'Document' &&
                            <Documents />
                        }
                        {
                            eightTab.label == 'Calendar' &&
                            <Calender />
                        }
                        {
                            eightTab.label == 'Keywords' &&
                            <Keywords />
                        }
                        {
                            eightTab.label == 'Work Orders' &&
                            <WorkOrders projectID={projectID} ProjectName={ProjectName} />
                        }
                        {
                            eightTab.label == 'Invoices' &&
                            <Invoices />
                        }
                        {
                            eightTab.label == 'Site Visits' &&
                            <SiteVisits />
                        }
                        {
                            eightTab.label == 'Reports' &&
                            <Reports />
                        }
                        {
                            eightTab.label == 'Deliverables ' &&
                            <Deliverables />
                        }
                    </TabPanel>
                    <TabPanel value={value} index={8}>

                    </TabPanel>
                    <TabPanel value={value} index={9}>

                    </TabPanel>
                    <TabPanel value={value} index={10}>

                    </TabPanel>


                    <TabPanel value={value} index={11}>

                    </TabPanel>
                    <TabPanel value={value} index={12}>

                    </TabPanel>
                    <TabPanel value={value} index={13}>

                    </TabPanel>

                </Box>

            </div >
            <Divider />

            {/* <div style={{ width: '95%', display: 'flex', justifyContent: 'end', marginTop: 5 }}>
                <Button variant="contained" onClick={handleClose} style={{ width: 'fit-content', mr: 7 }}>
                    Cancel
                </Button>

            </div> */}
        </>
    );
}
